import axios from "axios";

const state = {
    combos: []
};

const getters = {
    all(state) {
        return state.combos;
    }
};

const actions = {
    getCombos({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/combos").then(response => {
                commit("SET_COMBOS", response.data);
                resolve(response);
            });
        });
    },
    fetchCombo({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/combos/1").then(response => {
                resolve(response);
            });
        });
    },
    createCombo({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/combos", payload).then(response => {
                dispatch("getCombos");
                resolve(response);
            });
        });
    },
    updateCombo({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/combos/" + payload.id, payload.data)
                .then(response => {
                    dispatch("getCombos");
                    resolve(response);
                });
        });
    },
    removeCombo({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/combos/" + payload).then(response => {
                dispatch("getCombos");
                resolve(response);
            });
        });
    },
    updateComboProducts({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/combos/${payload.id}/products`, payload)
                .then(response => {
                    resolve(response);
                });
        });
    }
};

const mutations = {
    SET_COMBOS: (state, payload) => {
        state.combos = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
