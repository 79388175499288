import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
    mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            component: () => import("./layouts/Main.vue"),
            children: [
                {
                    path: "/",
                    name: "menu",
                    component: () => import("./components/Home.vue")
                },
                {
                    path: "/product/:id",
                    name: "menu-item",
                    component: () => import("./components/Item.vue")
                },
                {
                    path: "/cart",
                    name: "cart",
                    component: () => import("./components/Cart.vue")
                },
                {
                    path: "/checkout/:phone?",
                    name: "checkout",
                    component: () => import("./components/Checkout.vue")
                },
                {
                    path: "/order-success/:order_code",
                    name: "order-success",
                    component: () =>
                        import("./components/OrderSuccessGuest.vue")
                },
                {
                    path: "/login",
                    name: "user-login",
                    component: () => import("./components/auth/login.vue")
                },
                {
                    path: "/invite/:invitee",
                    name: "login-invite",
                    component: () => import("./components/auth/loginInvite.vue")
                },
                {
                    path: "/register",
                    name: "user-register",
                    component: () => import("./components/auth/Register.vue")
                },
                {
                    path: "/profile",
                    name: "user-profile",
                    component: () => import("./components/profile/Profile.vue")
                },
                {
                    path: "/notifications",
                    name: "user-notifications",
                    component: () =>
                        import("./components/profile/Notifications.vue")
                },
                {
                    path: "/profile/favourites",
                    name: "user-favourites",
                    component: () =>
                        import("./components/profile/Favourites.vue")
                },
                {
                    path: "/profile/orders/:type",
                    name: "user-profile-orders",
                    component: () =>
                        import("./components/profile/UserOrders.vue")
                },
                {
                    path: "/order-details/:order_id",
                    name: "user-order-details",
                    component: () =>
                        import("./components/profile/UserOrderDetails.vue")
                },
                {
                    path: "/profile/edit",
                    name: "user-profile-edit",
                    component: () =>
                        import("./components/profile/ProfileEdit.vue")
                },
                {
                    path: "/address-book",
                    name: "user-address-book",
                    component: () =>
                        import("./components/profile/AddressBook.vue")
                },
                {
                    path: "/login-admin",
                    name: "admin-login",
                    component: () => import("./components/auth/loginAdmin.vue")
                },
                {
                    path: "/checkout-login",
                    name: "checkout-login",
                    component: () =>
                        import("./components/auth/checkoutLogin.vue")
                },
                {
                    path: "/deals",
                    name: "deals",
                    component: () => import("./components/Deals.vue")
                },
                {
                    path: "/restaurant",
                    name: "restaurant",
                    component: () => import("./components/Restaurant.vue")
                },
                {
                    path: "/order/:order_id",
                    name: "tarck-order",
                    component: () => import("./components/TrackOrder.vue")
                },
                {
                    path: "/password-reset",
                    name: "password-reset",
                    component: () => import("./components/PasswordReset.vue")
                }
            ]
        },
        {
            path: "",
            component: () => import("./layouts/Dashboard.vue"),
            beforeEnter: (to, from, next) => {
                if (!localStorage.getItem("token"))
                    next({ name: "admin-login" });

                next();
            },
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () =>
                        import("./components/admin/AdminDashboard.vue")
                },
                {
                    path: "/products/:category_id/mass-upload",
                    name: "mass-upload",
                    component: () =>
                        import("./components/admin/products/MassUpload.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/users",
                    name: "users",
                    component: () => import("./components/admin/users/View.vue")
                },
                {
                    path: "/clients",
                    name: "clients",
                    component: () =>
                        import("./components/admin/clients/View.vue")
                },
                {
                    path: "/upload-clients",
                    name: "upload-clients",
                    component: () =>
                        import("./components/admin/clients/Mass.vue")
                },
                {
                    path: "/invited-clients",
                    name: "invited-clients",
                    component: () =>
                        import("./components/admin/clients/Invites.vue")
                },
                {
                    path: "/products/:category_id",
                    name: "products",
                    component: () =>
                        import("./components/admin/products/View.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/new-product/:category_id",
                    name: "new-product",
                    component: () =>
                        import("./components/admin/products/Curate.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/products/curate/:product_id?",
                    name: "products-curate",
                    component: () =>
                        import("./components/admin/products/Curate.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/categories",
                    name: "categories",
                    component: () =>
                        import("./components/admin/categories/View.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/categories/curate/:category_id?",
                    name: "categories-curate",
                    component: () =>
                        import("./components/admin/categories/Curate.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/categories/update-image/:category_id",
                    name: "categories-curate-image",
                    component: () =>
                        import("./components/admin/categories/UpdateImage.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/combos",
                    name: "combos",
                    component: () =>
                        import("./components/admin/combos/View.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/combos/curate/:combo_id?",
                    name: "combos-curate",
                    component: () =>
                        import("./components/admin/combos/Curate.vue"),
                    meta: {
                        rule: "operator"
                    }
                },
                {
                    path: "/orders",
                    name: "branches-orders",
                    component: () =>
                        import("./components/admin/orders/ViewBranches.vue")
                },
                {
                    path: "/branches/:id/orders",
                    name: "orders",
                    component: () =>
                        import("./components/admin/orders/ViewOrders.vue")
                },
                {
                    path: "/orders/curate/:order_id?",
                    name: "orders-curate",
                    component: () =>
                        import("./components/admin/orders/Curate.vue")
                },
                {
                    path: "/branches",
                    name: "branches",
                    component: () =>
                        import("./components/admin/branches/View.vue")
                },
                {
                    path: "/branches-mass-upload",
                    name: "branches-mass-upload",
                    component: () =>
                        import("./components/admin/branches/Mass.vue")
                },
                {
                    path: "/branches/curate/:branch_id?",
                    name: "branches-curate",
                    component: () =>
                        import("./components/admin/branches/Curate.vue")
                },
                {
                    path: "/branches/show/:branch_id",
                    name: "branches-show",
                    component: () =>
                        import("./components/admin/branches/Show.vue")
                },
                {
                    path: "/packages",
                    name: "packages",
                    component: () => import("./components/admin/deals/View.vue")
                },
                {
                    path: "/packages/curate/:code?",
                    name: "packages-curate",
                    component: () =>
                        import("./components/admin/deals/Curate.vue")
                },
                {
                    path: "/staff",
                    name: "staff",
                    component: () => import("./components/admin/staff/View.vue")
                },
                {
                    path: "/settings",
                    name: "settings",
                    component: () =>
                        import("./components/admin/settings/General.vue")
                },
                {
                    path: "/appearance",
                    name: "appearance",
                    component: () =>
                        import("./components/admin/appearance/View.vue")
                },
                {
                    path: "/landing-options",
                    name: "landing-options",
                    component: () =>
                        import("./components/admin/landing-options/View.vue")
                },
                {
                    path: "/landing-options/show/:id?",
                    name: "landing-options-ad",
                    component: () =>
                        import("./components/admin/landing-options/Ad.vue")
                },
                {
                    path: "/landing-option/show/group/:group_id?",
                    name: "landing-options-group",
                    component: () =>
                        import(
                            "./components/admin/landing-options/components/Group.vue"
                        )
                },
                {
                    path: "/services",
                    name: "services",
                    component: () => import("./components/admin/tax/View.vue")
                }
            ]
        },
        {
            path: "",
            component: () => import("./layouts/Wizard.vue"),
            children: [
                {
                    path: "/setup-restaurant",
                    name: "setup-restaurant",
                    component: () => import("./components/setup/Welcome.vue"),
                    meta: {
                        rule: "public"
                    }
                },
                {
                    path: "/shop-404",
                    name: "shop-not-setup",
                    component: () => import("./components/setup/Page404.vue"),
                    meta: {
                        rule: "public"
                    }
                }
            ]
        },
        {
            path: "",
            component: () => import("./layouts/Desktop.vue"),
            children: [
                {
                    path: "/landing",
                    name: "desktop-home",
                    component: () => import("./components/desktop/Home.vue")
                },
                {
                    path: "/pos/:phone?",
                    name: "pos",
                    component: () => import("./components/admin/pos/View.vue"),
                    beforeEnter: (to, from, next) => {
                        if (!localStorage.getItem("token"))
                            next({ name: "admin-login" });

                        next();
                    }
                }
            ]
        }
    ]
});

export default router;
