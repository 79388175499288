import axios from "./axios-auth";

const state = {
    deals: []
};

const getters = {
    all(state) {
        return state.deals;
    }
};

const actions = {
    getDeals({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/packages").then(response => {
                commit("SET_DEALS", response.data);
                resolve(response);
            });
        });
    },
    createDeal({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/packages", payload).then(response => {
                // console.log(response.data);
                resolve(response);
            });
        });
    },
    removeDeal({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/packages/" + payload).then(response => {
                resolve(response);
            });
        });
    }
};

const mutations = {
    SET_DEALS: (state, deals) => {
        state.deals = deals;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
