import axios from "./axios-auth";
import noauth from "axios";

const state = {
    zones: []
};

const getters = {
    all(state) {
        return state.zones;
    }
};

const actions = {
    getZones({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            noauth.get("/api/zones").then(response => {
                // console.log(response.data);
                commit("SET_ZONES", response.data);
                resolve(response);
            });
        });
    },
    createZone({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/zones", payload).then(response => {
                // commit('ADD_BRANCH', response.data.zone)
                resolve(response);
            });
        });
    },
    updateZone({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put("/api/zones/" + payload.id, payload).then(response => {
                resolve(response);
            });
        });
    },
    removeZone({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/zones/" + payload).then(response => {
                commit("REFRESH_ZONES");
                resolve(response);
            });
        });
    }
};

const mutations = {
    SET_ZONES: (state, zones) => (state.zones = zones),
    ADD_BRANCH: (state, zone) => state.zones.push(zone),
    REFRESH_ZONES: (state, zone_id) => {
        const index = state.zones.findIndex(zone => zone.id == zone_id);
        state.zones.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
