import axios from "./axios-auth";
import noauth from "axios";

const state = {
    addresses: []
};

const getters = {
    all(state) {
        return state.addresses;
    }
};

const actions = {
    getUserAddresses({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/addresses/" + payload).then(
                response => {
                    // console.log(response.data);
                    commit("SET_ADDRESSES", response.data.addresses);
                    window.location.replace("/checkout");
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    getaddresses({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/addresses/" + payload).then(
                response => {
                    // console.log(response.data);
                    commit("SET_ADDRESSES", response.data.addresses);
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    getaddress({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/addresses/" + payload + "/details").then(
                response => {
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    createAddress({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/addresses", payload).then(
                response => {
                    commit("ADD_ADDRESS", response.data.address);
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    updateAddress({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`/api/addresses/${payload.id}/edit`, payload).then(
                response => {
                    dispatch("getaddresses", payload.user_id);
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    removeAddress({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/addresses/" + payload).then(
                response => {
                    commit("REFRESH_ADDRESSES", payload);
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    clearAddresses({ commit }) {
        commit("CLEAR_USER_ADDRESSES");
    }
};

const mutations = {
    SET_ADDRESSES: (state, addresses) => (state.addresses = addresses),
    ADD_ADDRESS: (state, address) => state.addresses.push(address),
    REFRESH_ADDRESSES: (state, address_id) => {
        const index = state.addresses.findIndex(
            address => address.id == address_id
        );
        state.addresses.splice(index, 1);
    },
    CLEAR_USER_ADDRESSES: state => {
        state.addresses = [];
        console.log(state.addresses);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
