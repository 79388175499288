import axios from "./axios-auth";

const state = {
    services: []
};

const getters = {
    all(state) {
        return state.services;
    }
};

const actions = {
    getServices({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/services").then(response => {
                commit("SET_SERVICES", response.data);
                resolve(response);
            });
        });
    },
    createService({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/services", payload).then(response => {
                dispatch("getServices");
                resolve(response);
            });
        });
    },
    editService({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`/api/services/${payload.id}`, payload).then(response => {
                dispatch("getServices");
                resolve(response);
            });
        });
    },
    removeService({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`/api/services/${payload}`).then(response => {
                dispatch("getServices");
                resolve(response);
            });
        });
    },
    syncBranches({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/services/sync/${payload.service_id}`, payload)
                .then(response => {
                    dispatch("branches/getBranches", {}, { root: true });
                    resolve(response);
                });
        });
    }
};

const mutations = {
    SET_SERVICES: (state, services) => {
        state.services = services;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
