import axios from "./axios-auth";
import noauth from "axios";

const state = {
    branches: []
};

const getters = {
    all(state) {
        return state.branches;
    }
};

const actions = {
    getBranches({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            noauth.get("/api/branches").then(response => {
                // console.log(response.data);
                commit("SET_BRANCHES", response.data.branches);
                resolve(response);
            });
        });
    },
    getBranchesZones({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/branches-zones").then(response => {
                // console.log(response.data);
                resolve(response);
            });
        });
    },
    getBranch({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/branches/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    createBranch({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/branches", payload).then(response => {
                commit("ADD_BRANCH", response.data.branch);
                resolve(response);
            });
        });
    },
    createBranches({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/branches/mass-upload", payload).then(response => {
                console.log(response.data);
                resolve(response);
            });
        });
    },
    productAvailablity({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `/api/branches/${payload.branch}/availability/${payload.product}`
                )
                .then(response => {
                    // console.log(response.data);
                    resolve(response);
                });
        });
    },
    getProductsList({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/branches/${payload}/products-list`)
                .then(response => {
                    resolve(response);
                });
        });
    },
    updateBranch({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put("/api/branches/" + payload.id, payload).then(response => {
                resolve(response);
            });
        });
    },
    removeBranch({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/branches/" + payload).then(response => {
                commit("REFRESH_BRANCHES");
                resolve(response);
            });
        });
    },
    fetchServices({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/branches/${payload}/services`).then(response => {
                resolve(response);
            });
        });
    }
};

const mutations = {
    SET_BRANCHES: (state, branches) => (state.branches = branches),
    ADD_BRANCH: (state, branch) => state.branches.push(branch),
    REFRESH_BRANCHES: (state, branch_id) => {
        const index = state.branches.findIndex(
            branch => branch.id == branch_id
        );
        state.branches.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
