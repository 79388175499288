/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue").default;

import router from "./router";
import store from "./store/store";
import i18n from "./i18n/index";

import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
Vue.component("v-icon", Icon);

import VueNotification from "@mathieustan/vue-notification";

Vue.use(VueNotification, {
    theme: {
        colors: {
            success: "#34D399",
            info: "#818CF8",
            warning: "#FBBF24",
            error: "#F87171",
            offline: "#111827"
        }
    }
});

import VTooltip from "v-tooltip";

Vue.use(VTooltip);

import VueSweetalert2 from "vue-sweetalert2";

const options = {
    confirmButtonColor: "#059669",
    cancelButtonColor: "#ef4444"
};

import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2, options);

const app = new Vue({
    el: "#app",
    router,
    store,
    i18n
});
