import axios from "axios";

const state = {
    groups: [],
    ads: []
};

const getters = {
    groups(state) {
        return state.groups;
    },
    ads(state) {
        return state.ads;
    }
};

const actions = {
    getGroups({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/ads/groups").then(
                response => {
                    // console.log(response.data);

                    commit("SET_GROUPS", response.data);
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    getAds({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/ads").then(
                response => {
                    commit("SET_ADS", response.data);
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    getGroup({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/ads/groups/" + payload).then(
                response => {
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    getAd({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/ads/" + payload).then(
                response => {
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    createGroup({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/ads/groups", payload).then(
                response => {
                    dispatch("getGroups");
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    createAd({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/ads", payload).then(
                response => {
                    dispatch("getAds");
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    updateGroup({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/ads/groups/${payload.id}`, payload.data).then(
                response => {
                    dispatch("getGroups");
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    updateAd({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            if (payload.data instanceof FormData) {
                axios.post(`/api/ads/${payload.id}`, payload.data).then(
                    response => {
                        // console.log(response.data);
                        dispatch("getAds");
                        resolve(response);
                    },
                    error => {
                        console.log(error);
                    }
                );
            } else {
                axios.put(`/api/ads/${payload.id}`, payload.data).then(
                    response => {
                        // console.log(response.data);
                        dispatch("getAds");
                        resolve(response);
                    },
                    error => {
                        console.log(error);
                    }
                );
            }
        });
    },
    removeGroup({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/ads/groups/" + payload).then(
                response => {
                    dispatch("getGroups");
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    },
    removeAd({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/ads/" + payload).then(
                response => {
                    dispatch("getAds");
                    resolve(response);
                },
                error => {
                    console.log(error);
                }
            );
        });
    }
};

const mutations = {
    SET_GROUPS: (state, payload) => {
        state.groups = payload;
    },
    SET_ADS: (state, payload) => {
        state.ads = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
