import axios from "./axios-auth";
import noauth from "axios";
const state = {
    products: [],
    sides: []
};

const getters = {
    all(state) {
        return state.products;
    },
    sides(state) {
        return state.sides;
    }
};

const actions = {
    checkAvailability({}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/check-products-availability", payload)
                .then(response => {
                    resolve(response);
                });
        });
    },
    massUploadProducts({}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/products/mass-upload", payload).then(response => {
                resolve(response);
            });
        });
    },
    getProducts({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/products").then(response => {
                commit("SET_PRODUCTS", response.data.products);
                resolve(response);
            });
        });
    },
    getProductsList({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            noauth.get("/api/list-available-products").then(response => {
                resolve(response);
            });
        });
    },
    getProductsByCategory({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/products/category/" + payload).then(response => {
                // commit("SET_PRODUCTS", response.data.products);
                resolve(response);
            });
        });
    },
    getProduct({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/products/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    createProduct({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/products", payload).then(response => {
                // commit('ADD_PRODUCT', response.data.product)
                resolve(response);
            });
        });
    },
    updateProduct({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.put("/api/products/" + payload.id, payload).then(response => {
                resolve(response);
            });
        });
    },
    updateProductImage({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/api/products/update-product-image/" +
                        JSON.parse(payload.get("data")).id,
                    payload
                )
                .then(response => {
                    resolve(response);
                });
        });
    },
    removeProduct({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/products/" + payload).then(response => {
                commit("REFRESH_PRODUCTS");
                resolve(response);
            });
        });
    },
    fetchSideProducts({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/sides").then(response => {
                commit("SET_SIDES", response.data);
                resolve(response);
            });
        });
    }
};

const mutations = {
    SET_PRODUCTS: (state, products) => (state.products = products),
    SET_SIDES: (state, sides) => (state.sides = sides),
    ADD_PRODUCT: (state, product) => state.products.push(product),
    REFRESH_PRODUCTS: (state, product_id) => {
        const index = state.products.findIndex(
            product => product.id == product_id
        );
        state.products.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
