import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "../store/store";

Vue.use(VueI18n);

let locale = "ar";

export default new VueI18n({
    locale: locale,
    messages: {
        ar: {
            Back: "رجوع",
            Language: "تغيير اللغة",
            EN: "English",
            AR: "العربية",
            AppTitle: "مسلم",
            AddtoCart: "إضافة للسلة",
            HowMany: "حدد الكمية",
            DoneOrdering: "إلي السلة",
            ItemVariants: "يوجد {0} مقاسات من هذا الصنف",
            EGP: "ج.م",
            Choose: "إختيار",
            ChooseSize: "إختر الحجم",
            bot2: "قم بمراجعة طلبك وإضافة أي ملاحظات علي الأصناف التي طلبتها",
            Title: "الصنف",
            Qty: "الكمية",
            Size: "الحجم",
            Price: "السعر",
            Total: "إجمالي",
            Note: "ملاحظة",
            Next: "التالي",
            Previous: "السابق",
            user1: "قمت بمراجعة وتأكيد طلبي",
            bot3: "قم بإدخال بيانات التوصيل",
            Name: "الإسم",
            Phone: "الهاتف",
            Phone2: "هاتف إضافي (إختياري)",
            Zone: "توصيل إلي",
            PickZone: "إختر منطقة",
            AdjustZone: "تغيير المنطقة",
            Address: "العنوان",
            bot4: "قبل إنهاء طلبك وارساله للمطعم ألقي نظرة أخيرة عليه",
            TotalQuantity: "الكمية الكلية",
            TotalPrice: "السعر الكلي",
            DeliveryPrice: "التوصيل",
            Subtotal: "الإجمالي",
            Confirmation: "تأكيد الطلب",
            Cancel: "إلغاء",
            items: "صنف (أصناف)",
            MakeOrder: "طلب جديد",
            Password: "كلمة السر:",
            Login: "تسجيل الدخول",
            Guest: "المتابعة كضيف",
            Dashboard: "لوحة التحكم",
            Branches: "الفروع",
            Orders: "الطلبات",
            Products: "قائمة الطعام",
            Categories: "المجموعات",
            Users: "المستخدمين",
            Logout: "خروج",
            PendingOrders: "طلبات قيد التوصيل",
            ConfirmedOrders: "طلبات تم تأكيدها",
            CompletedOrders: "الطلبات المكتملة",
            CancelledOrders: "الطلبات الملغية",
            TotalOrders: "عدد الطلبات الكلي",
            Note: "ملاحظة",
            Add: "إضافة",
            View: "عرض",
            Edit: "تعديل",
            New: "جديد",
            Delete: "حذف",
            NA: "لا توجد بيانات للعرض",
            Branch: "فرع",
            BranchPhone: "هاتف الفرع",
            BranchAddressAR: "عنوان الفرع بالعربية",
            BranchAddressEN: "عنوان الفرع بالإنجليزية",
            Pending: "قيد الإنتظار",
            Confirmed: "تم التأكيد",
            OnTheWay: "في الطريق إليك",
            Completed: "تم التسليم",
            Cancelled: "ملغي",
            ViewAll: "عرض الكل",
            BranchOrders: "طلبات الفرع",
            ProductData: "بيانات الصنف",
            ProductVariants: "أحجام الصنف",
            ProductImages: "صور الصنف",
            Submit: "حفظ",
            Uplaod: "رفع الصور",
            Update: "تحديث",
            NewProduct: "إضافة صنف جديد",
            EditImage: "تعديل الصورة",
            Category: "مجموعة",
            ChooseCategory: "إختر المجموعة",
            description: "الوصف (إختياري)",
            bot5: "قم بتسجيل الدخول أو متابعة طلبك كضيف",
            bot6: "سيقوم فريقنا بالتواصل معك عند تأكيد طلبك",
            AddressBook: "قم بالإختيار من دليل عناوينك",
            MyInfo: "بياناتي",
            MyOrders: "طلباتي",
            MyAddressBook: "دفتر عناويني",
            NewOrder: "طلب جديد",
            Update: "تحديث",
            Name: "الإسم",
            Email: "البريد الإلكتروني",
            Password: "كلمة السر",
            PasswordMsg: "إملأ الحقل لتغيير كلمة السر",
            MyAddresses: "عناويني",
            AddAddress: "إضافة عنوان",
            EditAddress: "تعديل عنوان",
            Cart: "سلة المشتريات",
            Title: "الصنف",
            AddMore: "إضافة صنف",
            EmptyCart: "تفريغ السلة",
            CartEmpty: "السلة فارغة!",
            BrowseMenu: "تصفح قائمة الطعام",
            Home: "القائمة",
            TitleAR: "الإسم بالعربية",
            TitleEN: "الإسم بالإنجليزية",
            DescriptionAR: "الوصف بالعربية",
            DescriptionEN: "الوصف بالإنجليزية",
            AdURL: "رابط الإعلان",
            AdGroup: "مجموعة الإعلان",
            Ads: "الإعلانات",
            isAvilable: "متوفر؟",
            Current: "الحالية",
            ToCheckout: "إتمام الطلب",
            Receipt: "الإيصال",
            DeliveryInfo: "بيانات التوصيل",
            OrderPrepared: "طلبك قيد التأكيد",
            Approval: "موافق",
            invalidProvider:
                "رقم الهاتف يجب أن يبدأ بـ 010 أو 011 أو 012 أو 015",
            invalidLength: "رقم الهاتف يجب أن يكون من 11 خانة فقط",
            ChangeLang: "تغيير اللغة إلي",
            Profile: "الملف الشخصي",
            LoginMsg: "قم بتسجيل الدخول او المتابعة كضيف",
            SignMsg: "مرحبا! قم بالتسجيل او المتابعة كضيف",
            Hello: "مرحبا",
            Signup: "التسجيل",
            Success: "نجاح!",
            QRMSG: "إحفظ الكود لتتبع الطلب رقم",
            Save: "حفظ",
            Zones: "المناطق",
            ZonesD: "مناطق التوصيل",
            NameAR: "الإسم بالعربية",
            NameEN: "الإسم بالإنجليزية",
            OrdersbyBranches: "الطلبات حسب الفروع",
            Date: "التاريخ",
            Status: "الحالة",
            Action: "تحكم",
            Pending: "إنتظار",
            Confirmed: "تم التأكيد",
            // Completed: "مكتمل",
            Cancelled: "ملغي",
            Update: "تحديث",
            General: "تفاصيل عامة",
            Code: "الرقم المتسلسل",
            CartPrice: "إجمالي السلة",
            ProductQty: "عدد المنتجات",
            Notes: "ملاحظات",
            NN: "لا توجد ملاحظة",
            Promocodes: "التخفيضات",
            Edit: "تعديل",
            StreetNameNum: "العنوان بالتفصيل",
            BuildingNum: "رقم المبني",
            FloorNum: "رقم الطابق",
            FlatNum: "رقم الشقة",
            Landmark: "معلم بارز",
            building: "عمارة",
            Code: "الكود",
            Starts: "تاريخ البداية",
            Ends: "تاريح النهاية",
            Amount: "القيمة",
            Type: "النوع",
            Fixed: "قيمة ثابتة",
            Precentage: "نسبة مئوية",
            CreatePromocode: "كود جديد",
            editPromocode: "تعديل الكود",
            NewAccount: "إنشاء حساب جديد",
            Register: "إنشاء حساب",
            GuestOrders: "طلباتي كضيف",
            LatestDeals: "آخر العروض",
            Favorites: "المفضلة",
            Uploaded: "تم رفع",
            Reset: "حذف",
            ImgSize: "يجب ألايتجاوز حجم الصورة 1 ميجابايت. أعد رفع الصور",
            LoginError: "رقم الهاتف أو كلمة المرور خطأ حاول مجددا",
            Required: "هذه الخانة لا يمكن أن تترك فارغة",
            NameOOR: "عدد حروف الإسم يجب أن تكون أكثر من حرفين",
            PhoneOOR: "يجب أن يكون رقم الهاتف مكون من 11 رقم",
            PhoneMS: "رقم الهاتف يجب أن يبدأ بـ010 أو 011 أو 012 أو 015",
            PhoneUNQ: "هذا الرقم مسجل مسبقا",
            PhoneIV:
                "يجب أن يتكون رقم الهاتف من 11 خانة للهاتف أو 7 إلي 8 خانات للخط الأرضي مع الكود",
            PhoneIV2: "يجب أن يتكون رقم الهاتف من 9 خانة مع الكود",
            ShortenPhone: "عدد الخانات أطول من المطلوب",
            PasswordOOR: "كلمة المرور يجب أن تتكون من 6 خانات علي الأقل",
            PasswordNS: "يجب أن لا تحتوي كلمة السر علي مسافات",
            Success: "تمت العملية بنجاح",
            AddCategory: "إضافة مجموعة",
            AddProduct: "إضافة منتج",
            ShowProducts: "المنتجات",
            SetZone: "حدد أقرب منطقة توصيل للطلبات",
            SetDZone: "حدد المنطقة",
            Confirm: "تأكيد",
            NAZ: "لا توجد طلبات بدون مناطق توصيل",
            ChangeStatus: "تغيير الحالة",
            Change: "تغيير",
            BranchesStats: "إحصائيات الفروع",
            All: "الكل",
            Filter: "فلتر",
            Clear: "حذف",
            SearchByCode: "إبحث بالكود التعريفي",
            SuccessOrder: "طلبك قيد التحضير",
            Optional: "إختياري",
            TypeNotes: "أكتب ملاحظاتك",
            SaveAsImg: "إحفظ الكود",
            BrowseMenu: "تصفح الأصناف",
            RedirectWarning: "تنبيه لا يمكن الرجوع لهذه الصفحة بعد إغلاقها",
            inputAddress: "إضافة عنوان",
            NAA: "لا توجد عناوين للعرض قم بإضافة عنوان",
            ZoneP: "المنطقة",
            Search: "بحث",
            OrderNum: "كود الطلب",
            Product: "الصنف",
            Image: "الصورة",
            Ingredients: "مكونات من إختيارك",
            AddIngredients: "قم بإضافة المكونات",
            Clients: "العملاء",
            History: "تاريخ الطلبات",
            Joined: "تاريخ التسجيل",
            NotesClient: "ملاحظات علي العميل",
            Remove: "حذف",
            Extras: "مع إضافة",
            GuestMsg: "لم تسجل بعد؟",
            Staff: "الموظفين",
            CreateStaff: "إضافة موظف",
            UserRemove: "هل أنت متأكد من حذف هذا الموظف؟",
            ProductNA: "الصنف غير متوفر، بالرجاء حذفه",
            Description: "الوصف",
            NDA: "منطقة غير نشطة حاليا",
            AddressToBook: "سيتم إضافة العنوان المدخل إلي دفتر عناوينك",
            NITM: "بدون",
            WithExtra: "مع إضافة",
            Affrim: "هل أنت متأكد؟",
            InsertPhone: "أدخل رقم الهاتف",
            InsertPassword: "أنت عميل مسجل",
            Continue: "متابعة",
            OR: "أو",
            Grid: "بطاقات",
            List: "قائمة",
            MyOrders: "طلباتي",
            Notifications: "الإشعارات",
            CancelAddress: "الإختيار من عناويني",
            OrderSuccessNotification:
                "الطلب رقم {code} قيدالتحضير. سيتم إرسال إشعار آخر عند تغير الحالة.",
            CompleteMeal: "أكمل وجبتك",
            Saving: "تحميل...",
            MassUpload: "تحميل المنتجات",
            UploadProducts: "تحميل المنتجات",
            UploadAll: "تحميل كل المنتجات",
            DropFiles: "قم بإلقاء صور المنتجات هنا",
            SelectFiles: "إضغط لإختيار الصور",
            UploadProduct: "تحميل المنتج",
            AddProducts: "تحميل أكثر من منتج",
            isPrimary: "مجموعة رئيسية ؟",
            Deals: "العروض",
            NameRequired: "قم بإدخال الإسم",
            PhoneRequired: "قم بإدخال رقم الهاتف",
            AddressRequired: "الرجاء إدخال عنوان ومنطقة توصيل",
            AddressManual: "إدخال عنوان",
            Category: "مجموعة",
            VariantsError: "فضلا قم بإضافة حجم واحد علي الأقل",
            TodaysOrders: "طلبات اليوم",
            From: "من تاريخ",
            To: "إلي تاريخ",
            OK: "موافق",
            OrderReceivedPlain: "نشكرك.. تم إستلام الطلب رقم",
            OrderReceived: "نشكرك.. تم إستلام الطلب رقم {0}",
            OrderPending: "وجاري تأكيد الطلب",
            OrderConfirmed: "تم تأكيد الطلب رقم {0} وجاري التحضير",
            OrderOnTheWay: "الطلب {0} في الطريق إليك",
            OrderCompleted: "تم توصيل الطلب رقم {0}",
            ItemsQty: "عدد أصناف الطلب",
            Move: "نقل",
            MoveTo: "نقل إلي",
            AddMore: "إضافة المزيد",
            DeliveryMethod: "نوع الطلب",
            delivery: "توصيل للمنزل (دليڤري)",
            takeaway: "استلام من فرع (تيك أواي)",
            PickupTime: "توقيت الإستلام",
            FromBranch: "من فرع",
            ChooseBranch: "إختر الفرع",
            BranchRequired: "الرجاء إختيار الفرع لإستلام الطلب",
            SaveWAMessage: "إرسال كود الطلب للمطعم",
            ScreenshootOrder:
                "بالرجاء تصوير الشاشة للإحتفاظ بكود الطلب لتتمكن من تتبعه أو أرسله كرسالة واتساب للمطعم",
            Takeaway: "تيك أواي",
            Delivery: "توصيل",
            TakeawayTime: "زمن الإستلام",
            Settings: "إعدادات",
            GeneralSettings: "إعدادات عامة",
            RestrauntTitleAR: "إسم المطعم بالعربية",
            RestrauntTitleEN: "إسم المطعم بالإنجليزية",
            RestrauntPhone: "هاتف المطعم",
            RestrauntWAPhone: "رقم الواتساب",
            Logo: "الشعار",
            ImportantLinks: "روابط مهمة",
            Website: "الموقع الإلكتروني",
            Facebook: "فيسبوك",
            Twitter: "تويتر",
            Instagram: "إنستغرام",
            Youtube: "يوتيوب",
            WizardWelcome: "مرحبا! لنقم بإعداد مطعمك سويا",
            AdminProfile: "حساب الإدارة",
            HomeSlider: "صور العرض",
            ProuctSetup: "إضافة منتجات",
            Sorry: "عذرا",
            Message404: "المطعم تحت التشييد. بالرجاء الزيارة لاحقا",
            WorkStarts: "من",
            WorkEnds: "إلي",
            WorkDay: "يبدأ العمل",
            WorkHours: "عدد ساعات العمل",
            AdminName: "إسم صاحب المطعم",
            AdminPhone: "رقم الهاتف",
            AdminPhoneMsg: "سيتم إستخدام هذا الرقم لتسجيل الدخول للوحة التحكم",
            SliderMsg:
                "سيتم عرض هذه الصور في الصفحة الرئيسية. إختر صورة واحدة علي الأقل للمتابعة",
            Upload: "رفع",
            BranchSetup: "فرعك الرئيسي",
            MainBranchDetails:
                "يجب أن يحتوي مطعمك علي الأقل علي فرع واحد ومنطقة توصيل واحدة. الرجاء إدخال تفاصيل فرعك الرئيسي",
            AddZone: "إضافة منطقة توصيل",
            submitBranch: "إضافة الفرع ومناطق التوصيل",
            RestaurantSetup: "مبروك!",
            RestaurantSetupMsg:
                "لقد قمت بإعداد واجهة متجرك بنجاح قم بتسجيل الدخول لإضافة منتجاتك",
            AddProductsP:
                "رجاءا قم بإضافة مجموعة واحدة علي الأقل ليبدأ مطعمك العمل",
            ServiceMsg: "مواعيد العمل",
            RestrauntTitle: "إسم المطعم",
            MaxImages: "أقصي عدد للصور 12 صورة",
            SliderImages: "صور السلايدر",
            DragImages: "إضغط أو ضع الصور للرفع",
            SliderOverLimit: " تخطيت عدد الصور المسموح بها. سيتم رفع",
            Allowed: " من الصور المضافة",
            Optional: "إختياري",
            BranchLocationURL: "رابط موقع الفرع علي الخريطة",
            ExtraNotes: "ملاحظات إضافية",
            RepeatOrder: "إعادة طلب",
            RepeatedOrder: "هل تود طلب نفس الطلب بنفس الأصناف مرة أخري؟",
            checkOrder: "تتبع طلبك",
            GetStatus: "تتبع الطلب",
            GetStatusTxt:
                "يبدو أنك غير مسجل! هل تود التسجيل لمتابعة كل طلباتك أو قم بالمتابعة كضيف",
            OrderSettings: "إعدادات الطلبات",
            AddStatus: "إضافة حالة طلب جديدة",
            StatusColor: "لون الحالة",
            DiscountPrice: "سعر الخصم",
            Dishes: "الأصناف",
            Prices: "أسعار الأحجام",
            PhoneNumber: "رقم الهاتف",
            OrderCode: "كود الطلب",
            Appearance: "العرض",
            BackgroundColor: "لون خلفية التطبيق",
            PrimaryColor: "اللون الرئيسي",
            BorderRadius: "تكوير جوانب كروت الصورة",
            SliderHeight: "طول السلايدر",
            DiscountImageAR: "صورة العرض للغة العربية",
            DiscountImageEN: "صورة العرض للغة الإنجليزية",
            PX: "بيكسل",
            UserNotFound: "لا يوجد مستخدم أو طلبات بهذا الرقم",
            PasswordReset: "رجاءا قم بإنشاء كلمة سر جديدة",
            NewPasword: "كلمة السر الجديدة",
            ResetSuccess: "تم تغيير كلمة السر بنجاح، قم بتسجيل الدخول",
            UploadUsers: "إنشاء عملاء",
            Invited: "مدعو",
            Verified: "موثوق",
            SecurePassword: "كلمة سر آمنة",
            VerifyUser: "توثيق المستخدم",
            MassBranches: "رفع من ملف",
            RefreshedPage: "تم تحديث الصفحة",
            InvitedClients: "العملاء المدعويين",
            Invite: "رابط الدعوة",
            ShowMenu: "أطلب الآن",
            Landing: "الرئيسية",
            LandingPage: "صفحة الهبوط",
            NewAd: "إعلان جديد",
            NewAdGroup: "مجموعة إعلانات جديدة",
            VisitAd: "المزيد",
            PackageItems: "أصناف العرض",
            Show: "إظهار؟",
            HomeBackground: "خلفية الصفحة الرئيسية",
            AdsBackgroundColor: "لون خلفية الإعلانات",
            GridOptions: "خيارات عرض الإعلانات",
            GridColumnsIn: "عدد أعمدة العرض علي",
            MobileScreen: "شاشة الموبايل",
            TabletScreen: "شاشة التابلت",
            BigScreen: "الشاشات الأكبر",
            GridGap: "المساحة بين الأعمدة والصفوف (رقم بين 0 - 4)",
            ImageBR: "تكوير أطراف صورة القسم (نسبة مئوية)",
            AdBR: "تكوير أطراف صورة الإعلان (نسبة مئوية)",
            AdModalIH: "طول صورة الإعلان في المودال (بيكسل)",
            AdModalIV: "عرض صورة الإعلان في المودال (بيكسل)",
            TextColor: "لون الخط",
            TitleDetails: "إعدادات العنوان",
            TitleDescription: "إعدادات النص",
            FontSize: "حجم الخط (بيكسل)",
            TextAlign: "موقع النص",
            Center: "المنتصف",
            Left: "اليسار",
            Right: "اليمين",
            Justify: "ملأ السطر",
            FontWeight: "وزن الخط",
            Light: "خفيف",
            Normal: "عادي",
            Medium: "متوسط",
            Bold: "عريض",
            Width: "عرض الصورة",
            BoxShadow: "ظل الصورة",
            ZoneUnavailable: "هذه المنطقة غير متوفرة رجاءا قم بتغييريها",
            EditDeliveryMethod: "تعديل طريقة التوصيل",
            CustomerData: "بيانات العميل",
            NotAdded: "غير محدد",
            ContainerWidth: "عرض مساحة الصورة",
            Height: "الطول",
            ContentWidth: "عرض المحتوي",
            RestrauntDeliveryTime: "زمن التسليم للعميل (بالدقائق)",
            Select: "إختيار",
            PaymentMethod: "طريقة الدفع",
            cash: "الدفع عند الإستلام",
            visa: "دفع إلكتروني (visa)",
            ServicesTax: "الخدمات والضرائب",
            Addition: "يضاف",
            Subtraction: "يخصم",
            Priority: "الأولوية",
            Combo: "كومبو",
            Combos: "مجموعة الكومبو",
            AddCombo: "إضافة كومبو",
            EditCombo: "تعديل كومبو",
            ComboInstructions:
                "لإضافة منتجات للكومبو يجب اضافة مجموعات إختيار. داخل كل مجموعة عدد من الأصناف يخير بينها العميل.",
            AddOptionGroup: "إضافة مجموعة خيارات",
            NumberOfProducts: "عدد الأصناف",
            Package: "باكيج",
            Sizes: "الأحجام",
            Fries: "بطاطس",
            Drinks: "المشروبات",
            AlreadyChoosen:
                "تم إختيار المنتج الأساسي من  هذه المجموعة. لا يمكنك الإختيار منها",
            MakeCombo: "تحويل لوجبة (كومبو)",
            Adds: "يضاف",
            DesktopMesg: "يرجى استخدام الهاتف لطلب أصنافك المفضلة",
            SelectProduct: "تحديد المنتجات",
            TimeErrMsg: "الرجاء إختيار وقت بعد ساعة علي الاقل من الآن",
            Receipt: "ملخص الطلب",
            GUser: "عميل غير مسجل",
            MakeOrder: "طلب توصيل",
            POS: "طلبات التوصيل",
            ClientOrders: "طلبات العميل السابقة",
            ClientNotes: "تعليقات علي العميل",
            AddNote: "إضافة تعليق",
            Role: "الوظيفة",
            Operator: "مشغل",
            Employee: "موظف",
            Admin: "أدمن",
            CallCenter: "كول سنتر",
            CallCenterManager: "مدير كول سنتر",
            OrderCount: "عدد الطلبات",
            CancelledCount: "عدد الطلبات الملغية",
            FirstOrder: "تاريخ الطلب الأول",
            LastOrder: "تاريخ آخر طلب",
            OrderAvg: "متوسط الطلبات",
            Send: "إرسال",
            Other: "آخر",
            UnavailableProduct: "الصنف غير متوفر علي الفرع الحالي",
            NumOfOrders: "عدد الطلبات",
            SaveUser: "حفظ كعميل جديد",
            UpdateUser: "تعديل بيانات العميل",
            Appendix: "ملحق",
            AppendedTo: "ملحق علي",
            CannotDelete:
                "لا يمكن حذف الفرع مع وجود مناطق توصيل مربوطة به قم بتحويل المناطق إلي فرع:",
            DeliveredBy: "قام بالتوصيل",
            SwitchZone: "الرجاء تعديل منطقة التوصيل (منظقة محذوفة)",
            DaySubtotal: "مبيعات اليوم",
            AppendOrder: "هل تود إضافة الطلب الحالي علي الطلب السابق كملحق؟",
            AppendOrders: "إضافة ملحقات",
            AddPhone: "إضافة رقم هاتف",
            AddPhoneMessage: "إضافة رقم آخر",
            MustRegister: "يجب التسجيل كعميل جديد للمواصلة",
            ParentOrder: "طلب يحتوي ملحقات",
            ChildOrder: "طلب ملحق علي طلب آخر",
            NeutralOrder: "طلب بدون ملحقات",
            QtyByPrice: "حساب الكمية من السعر",
            InvalidAddress:
                "الرجاء تكملة بيانات العنوان الذي تم اختياره او اختيار عنوان آخر"
        },
        en: {
            All: "All",
            Back: "Back",
            Language: "Change Language",
            EN: "English",
            AR: "العربية",
            Home: "Menu",
            AppTitle: "Muslim",
            AddtoCart: "Add To Cart",
            HowMany: "How Many?",
            DoneOrdering: "To Cart",
            ItemVariants: "This product has {0} sizes",
            EGP: "EGP",
            Choose: "Choose",
            ChooseSize: "Choose Size",
            bot2:
                "Kindly review your items and add notes to any of them if you wish",
            Title: "Item",
            Qty: "Qty",
            Total: "Total",
            Price: "Price",
            Size: "Size",
            Note: "Note",
            Next: "Next",
            Previous: "Previous",
            user1: "I have reviewed and confirmed my order",
            bot3: "Let's get your delivery information",
            Name: "Name",
            Phone: "Phone",
            Phone2: "Additional Phone Number",
            Zone: "Which of the following zones is closest to you?",
            Address: "Delivery Address",
            bot4:
                "Before we pass order for admin confirmation here's a summary of your order",
            TotalQuantity: "Total Quantity",
            TotalPrice: "Total Price",
            DeliveryPrice: "Delivery Price",
            Subtotal: "Subtotal",
            Confirmation: "Confirmation",
            Cancel: "Cancel",
            items: "Item(s)",
            MakeOrder: "Make New Order",
            Password: "Password:",
            Login: "Login",
            Guest: "Continue as Guest",
            Dashboard: "Dashboard",
            Branches: "Branches",
            Orders: "Orders",
            Products: "Products",
            Categories: "Categories",
            Users: "Users",
            Logout: "Logout",
            PendingOrders: "Pending Orders",
            ConfirmedOrders: "Confirmed Orders",
            CompletedOrders: "Completed Orders",
            CancelledOrders: "Cancelled Orders",
            TotalOrders: "Total Orders",
            Note: "Note",
            Add: "Add",
            View: "View",
            Edit: "Edit",
            New: "New",
            Delete: "Delete",
            NA: "No data available to show",
            Branch: "Branch",
            BranchPhone: "Branch Phone",
            BranchAddressAR: "Branch Address (ar)",
            BranchAddressEN: "Branch Address (en)",
            Pending: "Pending",
            Confirmed: "Confirmed",
            Completed: "Delivered",
            OnTheWay: "On The Way",
            Cancelled: "Cancelled",
            ViewAll: "View All",
            BranchOrders: "Branch Orders",
            ProductData: "Product Data",
            ProductVariants: "Product Variants",
            ProductImages: "Product Images",
            Submit: "Submit",
            Uplaod: "Uplaod",
            NewProduct: "Add New Product",
            EditImage: "Edit Image",
            Category: "Category",
            ChooseCategory: "Choose Category",
            description: "description (optional)",
            bot5: "kindly log in or continue your order as a guest",
            bot6: "Our team will contact you after order is confirmed",
            AddressBook: "Choose an address from your address book",
            MyInfo: "Profile Information",
            Profile: "Profile",
            MyOrders: "Orders",
            MyAddressBook: "Address Book",
            NewOrder: "New Order",
            Update: "Update",
            Name: "Name",
            Phone: "Phone",
            Email: "Email",
            Password: "Password",
            PasswordMsg: "Leave Empty to keep current password",
            MyAddresses: "My Addresses",
            Cart: "Cart",
            Title: "Title",
            AddMore: "Add More",
            EmptyCart: "Empty Cart",
            CartEmpty: "Your cart is empty!",
            BrowseMenu: "Browse Menu",
            TitleAR: "Arabic Title",
            TitleEN: "English Title",
            DescriptionAR: "Description in arabic",
            DescriptionEN: "Description in english",
            AdURL: "Ad URL",
            AdGroup: "Ad Group",
            Ads: "Ads",
            isAvilable: "is Available?",
            Current: "Current",
            ToCheckout: "To Checkout",
            Receipt: "Receipt",
            DeliveryInfo: "Delivery Information",
            OrderPrepared: "Your order is being prepared",
            Approval: "Done",
            invalidProvider: "Number can only starts with 010, 011, 012 or 015",
            invalidLength: "Phone number must be 11 digits",
            ChangeLang: "Change Language To",
            Hello: "Hello",
            LoginMsg: "Kindly login or continue as guest",
            SignMsg: "Welcome! Signup or coninue as a Guest",
            Signup: "Signup",
            QRMSG: "Save your order code to track it",
            Success: "Success!",
            Save: "Save",
            Zones: "Zones",
            ZonesD: "Delivery Zones",
            NameAR: "Name (ar)",
            NameEN: "Name (en)",
            OrdersbyBranches: "Orders By Branch",
            Date: "Date",
            Status: "Status",
            Action: "Action",
            Pending: "Pending",
            Confirmed: "Confirmed",
            // Completed: "Completed",
            Cancelled: "Cancelled",
            Update: "Update",
            General: "General Details",
            Code: "Serial",
            CartPrice: "Cart Total",
            ProductQty: "Products Quantity",
            Notes: "Notes",
            NN: "No note available",
            Promocodes: "Promocodes",
            Edit: "Edit",
            StreetNameNum: "Street Name/Number",
            BuildingNum: "Bulding Number",
            FloorNum: "Floor Number",
            FlatNum: "Flat Number",
            Landmark: "Landmark",
            Code: "Code",
            Starts: "Starts",
            Ends: "Ends",
            Amount: "Amount",
            Type: "Type",
            Fixed: "Fixed",
            Precentage: "Precentage",
            CreatePromocode: "Create Promocode",
            editPromocode: "Edit Promocode",
            NewAccount: "Create New Account",
            Register: "Register",
            GuestOrders: "Orders as Guest",
            LatestDeals: "Latest Deals",
            Favorites: "Favorites",
            Uploaded: "Uploaded",
            Reset: "Reset",
            ImgSize: "Image size must not exceed 1 MB. Re-upload Please",
            LoginError: "Wrong phone number or password",
            Required: "This field is required",
            NameOOR: "The name must be at least 2 characters long",
            PhoneOOR: "The name must be 11 characters long",
            PhoneMS:
                "The phone must start with one of the following: 010, 011, 012, 015",
            PhoneUNQ:
                "This phone number has already been taken by another user",
            PhoneIV:
                "Phone number must be 11 (phone) or 7 - 8 digits (landline) digits long with code",
            PhoneIV2: "Phone number must be 9 digits long with code",
            ShortenPhone: "Phone is longer than needed",
            PasswordOOR: "The password must be at least 6 characters",
            PasswordNS: "Password cannot contain spaces",
            Success: "Operation Successfull",
            AddCategory: "Add Category",
            AddProduct: "Add Product",
            ShowProducts: "Show Products",
            SetZone: "Set the closest delivery zone for the following orders",
            SetDZone: "Set Zone",
            Confirm: "Confirm",
            NAZ: "There are no orders without zones set",
            ChangeStatus: "Change Status",
            Change: "Change",
            BranchesStats: "Branches Statistics",
            All: "",
            Filter: "Filter",
            Clear: "Clear",
            SearchByCode: "Search By Order Code",
            SuccessOrder: "Your order is being prepared",
            Optional: "Optional",
            SaveAsImg: "Save As Image",
            BrowseMenu: "Browse Menu",
            RedirectWarning:
                "Beware that you can't return this page after closing it",
            inputAddress: "Add Address",
            NAA: "No addresses to show add an address",
            ZoneP: "Zone",
            Search: "Search",
            OrderNum: "Order Serial",
            Product: "Product",
            Image: "Image",
            Ingredients: "Choose your modifiers",
            AddIngredients: "Add Ingredients",
            Clients: "Clients",
            History: "History",
            Joined: "Joined at",
            NotesClient: "Notes on Client",
            Remove: "Remove",
            Extras: "Extras",
            GuestMsg: "Not a member?",
            OrderReceived: "New Order Receieved",
            Staff: "Staff",
            CreateStaff: "Add Employee",
            UserRemove: "Are you sure you want to remve this employee?",
            ProductNA: "Product Not Available",
            Description: "Description",
            NAExtras: "No extras Available",
            NDA: "Delivery Currently Unavailable",
            AddressToBook: "The new address will be added to your address book",
            NITM: "No",
            WithExtra: "extra",
            Affrim: "Are you sure?",
            InsertPhone: "Enter Phone Number",
            InsertPassword: "Enter Password",
            Continue: "Continue",
            OR: "Or",
            Grid: "Grid",
            List: "List",
            MyOrders: "My Orders",
            Notifications: "Notifications",
            CancelAddress: "Choose from my address",
            OrderSuccessNotification:
                "Order #{code} is being prepared. Another notification will be sent on status change.",
            CompleteMeal: "Complete Your Meal",
            Saving: "Saving...",
            MassUpload: "Mass Upload",
            UploadProducts: "Upload Products",
            UploadAll: "Upload All Products",
            DropFiles: "Drop files anywhere to upload",
            SelectFiles: "Select Files",
            UploadProduct: "Upload Product",
            AddProducts: "Add Multiple Products",
            isPrimary: "Main Category?",
            Deals: "Deals",
            NameRequired: "Name is required!",
            PhoneRequired: "Phone is required!",
            AddressRequired: "Choose or enter an address and a delivery zone",
            Category: "Category",
            VariantsError: "Kindly add at least one size to this product",
            TodaysOrders: "Today's Orders",
            From: "From Date",
            To: "To Date",
            OK: "Ok",
            OrderReceivedPlain:
                "Thank You!.. An order with the following code has been recieved",
            OrderReceived: "Thank You!.. Order #{0} has been recieved",
            OrderPending: "and is pending confirmation",
            OrderConfirmed:
                "Order #{0} has been confirmed and is being prepared.",
            OrderOnTheWay: "Order #{0} is on the way",
            OrderCompleted: "Order #{0} is delivered",
            ItemsQty: "Items Ordered",
            Move: "Move",
            MoveTo: "Move To",
            AddMore: "Add More",
            DeliveryMethod: "Order Type",
            Delivery: "Deliver My Meal",
            Pickup: "Take Away",
            PickupTime: "I will pickup my meal at",
            FromBranch: "From the following branch",
            ChooseBranch: "Choose Branch",
            BranchRequired: "Choose a branch to pick up from",
            SaveWAMessage: "Send Order Code as WhatsApp message",
            ScreenshootOrder:
                "Kindly screenshoot your order barcode and code to track your order or send it to restraunt",
            TakeawayTime: "Pickup Time",
            Settings: "Settings",
            GeneralSettings: "General Settings",
            RestrauntTitleAR: "Restraunt Name (Arabic)",
            RestrauntTitleEN: "Restraunt Name (English)",
            RestrauntPhone: "Restraunt Phone",
            RestrauntWAPhone: "Whatsapp Number",
            Logo: "Logo",
            ImportantLinks: "Important Links",
            Website: "Website",
            Facebook: "Facebook",
            Twitter: "Twitter",
            Instagram: "Instagram",
            Youtube: "Youtube",
            WizardWelcome: "Hello! Let's setup your restaurant together",
            AdminProfile: "Admin Profile",
            HomeSlider: "Home Slider",
            ProuctSetup: "Prouct Setup",
            Sorry: "Sorry",
            Message404:
                "The restaurant is under construction, come back later please",
            WorkStarts: "from",
            WorkEnds: "to",
            WorkHours: "Work Hours",
            WorkDay: "Work day starts",
            AdminName: "Restaurant Owner",
            AdminPhone: "Admin Phone",
            AdminPhoneMsg: "This number will be used to login to dashboard",
            SliderMsg:
                "Those images will be displayed in the home page. upload at least one image to continue",
            Upload: "Upload",
            BranchSetup: "Main Branch",
            MainBranchDetails:
                "You must have at least one branch and one delivery zone on your restraunt> Kindly fill your restaurant's main branch information below",
            AddZone: "Add Delivery Zone",
            submitBranch: "Add Branch and Delivery Zones",
            RestaurantSetup: "Congratulations!",
            RestaurantSetupMsg:
                "Your restaurant management is setup, Login to add your categories and products",
            AddProducts:
                "Kindly add at least one category and one product in that category to start accepting orders",
            RestrauntTitle: "Restraunt Title",
            MaxImages: "Maximum images for the slider is 12 images",
            SliderImages: "Slider Images",
            DragImages: "Drop images here to upload",
            SliderOverLimit:
                "You have exceeded the number of allow slider photos only ",
            Allowed: " Of your uploaded images will be added",
            Optional: "Optional",
            BranchLocationURL: "Branch Google Maps Link",
            ExtraNotes: "Extra Notes",
            RepeatOrder: "Repeat Order",
            RepeatedOrder:
                "Would you like to order the same order with the same products again?",
            checkOrder: "Track Your Orders",
            GetStatus: "Track My Order",
            GetStatusTxt:
                "You seem to be not registered! would you like to register to view all your orderds? Or continue as a guest",
            AddAddress: "Add Address",
            EditAddress: "Edit Address",
            OrderSettings: "Orders Settings",
            AddStatus: "Add new order status",
            StatusColor: "Status Color",
            DiscountPrice: "Discount Price",
            Dishes: "Products",
            Prices: "Product Prices",
            PhoneNumber: "Phone Number",
            OrderCode: "Order Code",
            Appearance: "Appearance",
            BackgroundColor: "Background Color",
            PrimaryColor: "Primary Color",
            BorderRadius: "BorderRadius",
            SliderHeight: "Slider Height",
            DiscountImageAR: "Discount Image (AR)",
            DiscountImageEN: "Discount Image (EN)",
            PX: "Pixel (px)",
            UserNotFound: "No users or orders found with this number",
            PasswordReset: "Kindly reset your password",
            NewPasword: "New Pasword",
            ResetSuccess: "Password reset successful! Login with new password",
            UploadUsers: "Upload Users",
            Invited: "Invited",
            Verified: "Verified",
            SecurePassword: "Secure Password",
            VerifyUser: "Verify User",
            MassBranches: "Upload from Excel",
            RefreshedPage: "Page Refreshed",
            InvitedClients: "Invited Clients",
            Invite: "Invite URL",
            ShowMenu: "Order Now",
            Landing: "Home",
            LandingPage: "Customize Landing Page",
            NewAd: "New Ad",
            NewAdGroup: "New Ad Group",
            VisitAd: "More",
            PackageItems: "Package Items",
            Show: "Show?",
            HomeBackground: "Landing Page Background Image",
            AdsBackgroundColor: "Ads Background Color",
            GridOptions: "Grid Options",
            GridColumnsIn: "Grid Columns In",
            MobileScreen: "Mobile Screen",
            TabletScreen: "Tablet Screen",
            BigScreen: "Larger Screens",
            GridGap: "Grid Gap",
            ImageBR: "Section Border Radius",
            AdBR: "Ad  Border Radius",
            AdModalIH: "Ad Modal Image Height",
            AdModalIV: "Ad Modal Image Width",
            TextColor: "Text Color",
            TitleDetails: "Title Details",
            ZoneUnavailable:
                "This zone is unavailable kindly edit the address and change it",
            EditDeliveryMethod: "Edit Delivery Method",
            CustomerData: "Customer Information",
            NotAdded: "Not Specified",
            ContainerWidth: "Image Container Width",
            Height: "Height",
            ContentWidth: "Content Width",
            TypeNotes: "type your notes...",
            RestrauntDeliveryTime: "Delivery Time (minutes)",
            Select: "Select",
            PaymentMethod: "Payment Method",
            cash: "Cash on Delivery",
            visa: "Visa",
            ServicesTax: "Services & Taxes",
            Addition: "Addition",
            Subtraction: "Subtraction",
            Priority: "Priority",
            Combos: "Combos",
            AddCombo: "Add Combo",
            EditCombo: "Edit Combo",
            ComboInstructions:
                "In order to add products to a combo you must create option groups that include a few products that the end user will choose between.",
            AddOptionGroup: "Add Option Group",
            NumberOfProducts: "Number Of Products",
            MakeCombo: "Make It Combo",
            Adds: "Adds",
            DesktopMesg: "Kindly use your phone to order your favorite meals",
            SelectProduct: "Select Product",
            TimeErrMsg: "Please pick a time at least 1 hour away",
            Receipt: "Receipt",
            GUser: "User not registered",
            AddressManual: "Input Address",
            MakeOrder: "Delivery Order",
            POS: "Call Center",
            ClientOrders: "Client Orders",
            ClientNotes: "Client Notes",
            AddNote: "Add Note...",
            Role: "Role",
            Employee: "Employee",
            Admin: "Admin",
            Operator: "Operator",
            CallCenter: "Call Center",
            CallCenterManager: "Call Center Manager",
            OrderCount: "Order Count",
            CancelledCount: "Cancelled Orders Count",
            FirstOrder: "First Order Date",
            LastOrder: "Last Order Date",
            OrderAvg: "Order Average",
            Other: "other",
            UnavailableProduct: "This product is unavailable in current branch",
            NumOfOrders: "Number of Orders",
            SaveUser: "Save New Customer",
            UpdateUser: "Update User",
            Appendix: "Free Delivery",
            AppendedTo: "Parent Order",
            CannotDelete:
                "Can't delete branch while delivery zones are associated with it, move delivery zones on this branch to another branch first. to branch :",
            DeliveredBy: "Delivered By",
            SwitchZone: "Kindly choose another zone (Deleted Zone)",
            DaySubtotal: "Today's Sale",
            AppendOrder:
                "Would you like to append current order to previous order?",
            AppendOrders: "Append Orders",
            AddPhone: "Add Phone",
            AddPhoneMessage: "Add phone numbers to user's phone book",
            MustRegister: "You must register new user first to continue",
            ParentOrder: "Parent Order",
            ChildOrder: "Appended Order",
            NeutralOrder: "Regular Order",
            QtyByPrice: "Calculate qty from provided price",
            InvalidAddress:
                "Kindly fill the chosen address fields or choose another address to continue"
        }
    }
});
