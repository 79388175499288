import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

// store.js
import i18n from "../i18n/index.js";

import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

// modules
import ads from "./modules/ads";
import auth from "./modules/auth";
import zones from "./modules/zones";
import users from "./modules/users";
import deals from "./modules/deals";
import combos from "./modules/combos";
import orders from "./modules/orders";
import products from "./modules/products";
import services from "./modules/services";
import branches from "./modules/branches";
import addresses from "./modules/addresses";
import categories from "./modules/categories";
import promocodes from "./modules/promocodes";

// functions
const objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length &&
          Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

const checkOptions = (cart, extras, ingredients) => {
    let extras_match = checkExtras(cart, extras);
    let ingredients_match = checkIngredients(cart, ingredients);

    return {
        extras: extras_match,
        modifiers: ingredients_match
    };
};

const checkExtras = (cart, extras) => {
    for (const item of cart) {
        let exids = item.extras.map(ex => ex.id);

        if (JSON.stringify(extras) == JSON.stringify(exids)) {
            return {
                match: true,
                item: cart.findIndex(el => el.product_id == item.product_id)
            };
        } else {
            return { match: false };
        }
    }
};

const checkIngredients = (cart, ingredients) => {
    for (const item of cart) {
        let ingids = item.ingredients.map(ex => ex.id);

        if (JSON.stringify(ingredients) == JSON.stringify(ingids)) {
            return {
                match: true,
                item: cart.findIndex(el => el.product_id == item.product_id)
            };
        } else {
            return { match: false };
        }
    }
};

const createID = (product, size, extras, modifiers) => {
    let unique = JSON.stringify(product) + "-" + JSON.stringify(size);
    unique += extras.length > 0 ? "-" + extras.join(".") : "-0";
    unique += modifiers.length > 0 ? "-" + modifiers.join(".") : "-0";

    return unique;
};

export default new Vuex.Store({
    state: {
        weAreOpen: true,
        thereIsAModalOpen: false,
        landingOptions: {},
        landingMobileMenu: true,
        isFoodMenuOpen: false,
        appearance: {},
        currentCategory: 0,
        currentView: "list",
        dashboardSidebar: false,
        settings: {},
        screenSize: null,
        lang: "ar",
        cart: [],
        totalQty: 0,
        totalPrice: 0,
        step: 1,
        deliveryPrice: 20,
        deliveryInfo: {
            name: "",
            phone: "",
            phone2: "",
            address: {
                id: null,
                street: "",
                building: "",
                floor: "",
                flat: "",
                zone_id: null
            }
        }
    },
    getters: {
        weAreOpen(state) {
            return state.weAreOpen;
        },
        thereIsAModalOpen(state) {
            return state.thereIsAModalOpen;
        },
        landingScreen(state) {
            return state.landingMobileMenu;
        },
        landing(state) {
            return state.landingOptions;
        },
        desktopFoodMenu(state) {
            return state.isFoodMenuOpen;
        },
        appearance(state) {
            return state.appearance;
        },
        view(state) {
            return state.currentView;
        },
        sidebarState(state) {
            return state.dashboardSidebar;
        },
        current(state) {
            return state.currentCategory;
        },
        screen(state) {
            return state.screenSize;
        },
        settings(state) {
            return state.settings;
        },
        logo(state) {
            return state.settings.restaurant_logo;
        },
        cart(state) {
            return state.cart;
        },
        tq(state) {
            return state.totalQty;
        },
        tp(state) {
            return state.totalPrice;
        },
        dp(state) {
            return state.deliveryPrice;
        },
        step(state) {
            return state.step;
        },
        delivery_details(state) {
            return state.deliveryInfo;
        }
    },
    actions: {
        async setAModalAsOpen({ commit }, payload) {
            commit("SET_MODAL_OPEN", payload);
        },
        toggleLandingMobileMenu({ commit }, payload) {
            commit("LANDING_MENU_TOGGLE", payload);
        },
        getLandingOptions({ commit }) {
            axios.get("/api/landings").then(res => {
                commit("SET_LANDING_OPTIONS", res.data);
            });
        },
        toggleFoodMenu({ commit }) {
            commit("TOGGLE_FOOD_MENU");
        },
        setViewMode({ commit }, paylaod) {
            commit("SET_VIEW_MODE", paylaod);
        },
        setScreenWidth({ commit }, payload) {
            commit("SET_SCREEN_WIDTH", payload);
        },
        toggleSidebar({ commit }) {
            commit("TOGGLE_SIDEBAR");
        },
        setCurrentCategory({ commit }, payload) {
            commit("SET_CURRENT_CATEGORY", payload);
        },
        async addItemToCart({ commit }, payload) {
            await commit("ADD_PRODUCT_TO_CART", payload);
        },
        emptyCart({ commit }) {
            commit("EMPTY_CART");
        },
        resetOrder({ commit }) {
            commit("RESET_ORDER");
        },
        async setDeliveryDetails({ commit }, payload) {
            await commit("SET_DELIVERY_INFO", payload);
        },
        async setDeliveryAddress({ commit }, payload) {
            await commit("SET_DELIVERY_INFO", payload);
        },
        async incrementCart({ commit }, payload) {
            await commit("INCREMENT_CART", payload);
        },
        async decrementCart({ commit }, payload) {
            await commit("DECREMENT_CART", payload);
        },
        async removeItems({ commit }) {
            await commit("EMPTY_CART_ITEMS");
        },
        async unshiftExtra({ commit }, payload) {
            await commit("REMOVE_EXTRA", payload);
        },
        async switchLanguage({ commit }) {
            await commit("SWITCH_LANGUAGE");
        },
        setSettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/settings").then(
                    response => {
                        // console.log(response.data);
                        commit("SET_SETTINGS", response.data);
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        checkSettings({}) {
            return new Promise((resolve, reject) => {
                axios.get("/api/settings/check").then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        checkProducts({}) {
            return new Promise((resolve, reject) => {
                axios.get("/api/settings/check-products").then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchWorkHours({}) {
            return new Promise((resolve, reject) => {
                axios.get("/api/settings/work-hours").then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchSliderImages({}) {
            return new Promise((resolve, reject) => {
                axios.get("/api/settings/slides").then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        createGeneralSettings({}, paylaod) {
            return new Promise((resolve, reject) => {
                axios.post("/api/settings/create", paylaod).then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        attachAdmin({}, paylaod) {
            return new Promise((resolve, reject) => {
                axios.post("/api/settings/attach", { admin_id: paylaod }).then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        updateSocialLinks({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/settings/${payload.id}/update-links`, payload)
                    .then(
                        response => {
                            dispatch("setSettings");
                            resolve(response);
                        },
                        error => {
                            reject(error);
                        }
                    );
            });
        },
        updateRestaurantSettings({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `/api/settings/${payload.id}/update-settings`,
                        payload
                    )
                    .then(
                        response => {
                            dispatch("setSettings");
                            resolve(response);
                        },
                        error => {
                            reject(error);
                        }
                    );
            });
        },
        updateRestaurantAvatar({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `/api/settings/${payload.id}/update-avatar`,
                        payload.data
                    )
                    .then(
                        response => {
                            dispatch("setSettings");
                            resolve(response);
                        },
                        error => {
                            reject(error);
                        }
                    );
            });
        },
        getAppearanceSettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/appearances").then(
                    response => {
                        commit("SET_APPEARANCE", response.data);
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        updateAppearanceSettings({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("/api/appearances/1", payload).then(
                    response => {
                        // console.log(response.data);
                        commit("SET_APPEARANCE", response.data.appearance);
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        outOfStockAlert({ commit }, payload) {
            commit("OUT_OF_STOCK", payload);
        },
        async addOrderNotes({ commit }, product) {
            await commit("ADD_ORDER_ITEM_NOTES", product);
        },
        async removeItemFromCart({ commit }, payload) {
            await commit("REMOVE_CART_ITEM", payload);
        },
        async setRestaurantOpen({ commit }, payload) {
            await commit("SET_RETAURANT_OPEN", payload);
        },
        async removeFromCart({ commit }, paylaod) {
            await commit("REMOVE_FROM_CART", paylaod);
        }
    },
    mutations: {
        SET_RETAURANT_OPEN: (state, data) => {
            state.weAreOpen = data;
        },
        ADD_ORDER_ITEM_NOTES: (state, data) => {
            state.cart[data.index].notes = data.notes;
        },
        REMOVE_CART_ITEM: (state, data) => {
            state.totalQty -= state.cart[data].qty;
            state.totalPrice -= state.cart[data].price;
            state.cart.splice(data, 1);

            if (state.cart.length == 0) {
                window.location.replace("/");
            }
        },
        OUT_OF_STOCK: (state, index) => {
            state.cart[index].isAvailable = false;
            console.log(state.cart[index]);
            state.cart.splice(index, 1, state.cart[index]);
        },
        SET_MODAL_OPEN: (state, payload) => {
            state.thereIsAModalOpen = payload;
        },
        LANDING_MENU_TOGGLE: (state, payload) => {
            state.landingMobileMenu = payload;
        },
        SET_LANDING_OPTIONS: (state, options) => {
            state.landingOptions = options;
        },
        SET_APPEARANCE: (state, appearance) => {
            state.appearance = appearance;
        },
        SET_VIEW_MODE: (state, payload) => {
            state.currentView = payload;
        },
        SET_SETTINGS: (state, settings) => {
            state.settings = settings;
        },
        SWITCH_LANGUAGE: state => {
            if (state.lang == "en") {
                state.lang = "ar";
                i18n.locale = state.lang;
                document.documentElement.dir = "rtl";
            } else {
                state.lang = "en";
                i18n.locale = state.lang;
                document.documentElement.dir = "ltr";
            }
        },
        EMPTY_CART: state => {
            state.cart = [];
            state.totalQty = 0;
            state.totalPrice = 0;
        },
        RESET_ORDER: state => {
            localStorage.setItem("step", 1);
            state.deliveryInfo = {
                name: "",
                phone: "",
                branch_id: 0,
                delivery_address: ""
            };
        },
        ADD_PRODUCT_TO_CART: (state, item) => {
            // get an array of extras
            let extras_ids = item.extras.map(el => el.id);
            let ingredients_ids = item.ingredients.map(el => el.id);
            // console.log(extras_ids, ingredients_ids);

            let uniqueID = createID(
                item.product_id,
                item.variant_id,
                extras_ids,
                ingredients_ids
            );

            let index = state.cart.findIndex(
                el =>
                    el.identifier == uniqueID &&
                    el.display_name == item.display_name
            );

            if (index != -1) {
                // increase qty
                console.log(state.cart[index].qty);
                state.cart[index].qty =
                    parseFloat(state.cart[index].qty) + parseFloat(item.qty);
                console.log(state.cart[index].qty);

                // calculate price
                let productPrice = item.price;
                let extrasPrice = 0;

                if (item.extras.length > 0) {
                    // extract prices
                    let prices = item.extras.map(el => el.price);

                    // sum extras prices
                    extrasPrice = prices.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue
                    );
                }

                let totalPrice = (productPrice + extrasPrice) * item.qty;

                // console.log(state.totalPrice, totalPrice);
                // increase cart total
                state.totalPrice += totalPrice;

                // increase total quantity
                state.totalQty += item.qty;
            } else {
                // new item
                item.qty = parseFloat(item.qty);
                let new_cart_item = item;
                new_cart_item.identifier = uniqueID;

                state.cart.push(new_cart_item);
                state.totalQty += parseFloat(item.qty);
                state.totalPrice += item.price * parseFloat(item.qty);

                if (item.extras.length > 0) {
                    item.extras.forEach(element => {
                        state.totalPrice +=
                            element.price * parseFloat(item.qty);
                    });
                }
            }
        },
        REMOVE_EXTRA: (state, data) => {
            let { item, id } = data;

            // remove extra id from cart item identifier
            if (
                state.cart[item].identifier.split("-")[2].split(".").length == 1
            ) {
                // state.cart[item].identifier =
                let string = state.cart[item].identifier.split("-");
                string[2] = "0";
                state.cart[item].identifier = string.join("-");
            } else {
                let extras = state.cart[item].identifier
                    .split("-")[2]
                    .split(".");
                let remidx = extras.findIndex(ex => ex == id);
                extras.splice(remidx, 1);

                let new_extras = state.cart[item].identifier.split("-");
                new_extras[2] = extras.join(".");
                state.cart[item].identifier = new_extras.join("-");
            }

            // decrease total cart price
            let idx = state.cart[item].extras.findIndex(el => el.id == id);
            state.totalPrice -=
                state.cart[item].extras[idx].price * state.cart[item].qty;

            state.cart[item].extras.splice(idx, 1);
        },
        INCREMENT_CART: (state, index) => {
            // calculate total price
            let productPrice = state.cart[index].price;
            let extrasPrice = 0;

            if (state.cart[index].extras.length > 0) {
                let prices = state.cart[index].extras.map(el => el.price);
                extrasPrice = prices.reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                );
            }

            let totalPrice = productPrice + extrasPrice;

            state.cart[index].qty++;
            state.totalQty++;
            state.totalPrice += totalPrice;
        },
        DECREMENT_CART: (state, index) => {
            // calculate total price
            let productPrice = state.cart[index].price;
            let extrasPrice = 0;

            if (state.cart[index].extras.length > 0) {
                let prices = state.cart[index].extras.map(el => el.price);
                extrasPrice = prices.reduce(
                    (accumulator, currentValue) => accumulator + currentValue
                );
            }

            let totalPrice = productPrice + extrasPrice;

            if (state.cart[index].qty > 1) {
                state.cart[index].qty--;
                state.totalQty--;
                state.totalPrice -= totalPrice;
            } else {
                // let prompt = confirm(i18n.t("Affrim"));

                // if (prompt) {
                // console.log(index, state.cart[index]);
                state.totalQty -= state.cart[index].qty;
                state.totalPrice -=
                    state.cart[index].price * state.cart[index].qty;

                // remove item from cart
                state.cart.splice(index, 1);
                // }
            }
        },
        REMOVE_FROM_CART: (state, index) => {
            state.totalQty -= state.cart[index].qty;
            state.totalPrice -= state.cart[index].price * state.cart[index].qty;
            state.cart.splice(index, 1);
        },
        EMPTY_CART_ITEMS: state => {
            state.cart = [];
            state.totalQty = 0;
            state.totalPrice = 0;
        },
        SET_DELIVERY_INFO: (state, info) => {
            state.deliveryInfo = info;
        },
        SET_DELIVERY_ADDRESS: (state, address) => {
            state.deliveryInfo.delivery_address = address;
        },
        ADD_NOTE_TO_ITEM: (state, payload) => {
            let item = state.cart.find(c => c.id == payload.id);
            item.notes = payload.note;
        },
        SET_SCREEN_WIDTH: (state, width) => {
            state.screenSize = width;
        },
        SET_CURRENT_CATEGORY: (state, payload) => {
            state.currentCategory = payload;
        },
        TOGGLE_SIDEBAR: state => {
            state.dashboardSidebar = !state.dashboardSidebar;
        },
        TOGGLE_FOOD_MENU: state => {
            state.isFoodMenuOpen = !state.isFoodMenuOpen;
        }
    },
    modules: {
        ads,
        auth,
        users,
        orders,
        products,
        branches,
        categories,
        combos,
        zones,
        addresses,
        promocodes,
        deals,
        services
    },
    plugins: [vuexLocal.plugin]
});
