import axios from "./axios-auth";
// import noauth from "axios";

const state = {
    promocodes: []
};

const getters = {
    all(state) {
        return state.promocodes;
    }
};

const actions = {
    getPromocodes({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/promocodes").then(response => {
                // console.log(response.data);
                commit("SET_PROMOCODES", response.data);
                resolve(response);
            });
        });
    },
    getPromocode({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/promocodes/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    createPromocode({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/promocodes", payload).then(response => {
                commit("ADD_PROMOCODE", response.data.code);
                resolve(response);
            });
        });
    },
    updatePromocode({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/api/promocodes/" + payload.id, payload)
                .then(response => {
                    // commit("ADD_PROMOCODE", response.data.code);
                    resolve(response);
                });
        });
    },
    removePromocode({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/promocodes/" + payload).then(response => {
                commit("REFRESH_PROMOCODES", payload);
                resolve(response);
            });
        });
    }
};

const mutations = {
    SET_PROMOCODES: (state, promocodes) => (state.promocodes = promocodes),
    ADD_PROMOCODE: (state, promocode) => state.promocodes.push(promocode),
    REFRESH_PROMOCODES: (state, promocode_id) => {
        const index = state.promocodes.findIndex(
            promocode => promocode.id == promocode_id
        );
        state.promocodes.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
