import axios from "./axios-auth";

const state = {
    orders: [],
    statuses: []
};

const getters = {
    all(state) {
        return state.orders;
    },
    statuses(state) {
        return state.statuses;
    }
};

const actions = {
    getOrders({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/orders").then(response => {
                commit("SET_ORDERS", response.data.orders);
                resolve(response);
            });
        });
    },
    getNoZoneOrders({}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/no-zone-orders").then(response => {
                resolve(response);
            });
        });
    },
    getOrdersByUser({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/orders/user/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    getOrdersByPhone({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/orders/phone/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    getBranchOrder({}, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/branches/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    getOrder({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/orders/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    fetchOrder({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/orders/code/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    getOrderStatus({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/orders/${payload}/status`).then(response => {
                resolve(response);
            });
        });
    },
    getUserOrders({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.get("/api/orders/user/" + payload).then(response => {
                resolve(response);
            });
        });
    },
    createOrder({ commit }, payload) {
        console.log("Creating...");
        return new Promise((resolve, reject) => {
            axios.post("/api/orders", payload).then(response => {
                commit("ADD_ORDER", response.data.order);
                resolve(response);
            });
        });
    },
    createCallcenterOrder({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/orders/callcenter", payload).then(response => {
                commit("ADD_ORDER", response.data.order);
                resolve(response);
            });
        });
    },
    updateOrderStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/orders/${payload.id}/update-status`, payload)
                .then(response => {
                    resolve(response);
                });
        });
    },
    updateOrder({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.put("/api/orders/" + payload.id, payload).then(response => {
                resolve(response);
            });
        });
    },
    removeOrderProduct({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/orders/remove-order-product/" + payload.id, payload)
                .then(response => {
                    if (response.data.statusCode === 200) {
                        dispatch("getOrder", payload.id);
                        resolve(response);
                    }
                });
        });
    },
    removeOrder({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/orders/" + payload).then(response => {
                commit("REFRESH_ORDERS");
                resolve(response);
            });
        });
    },
    getStatuses({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/api/statuses").then(response => {
                commit("SET_STATUSES", response.data);
                resolve(response);
            });
        });
    },
    addNewStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/api/statuses", payload).then(response => {
                resolve(response);
            });
        });
    },
    updateStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`/api/statuses/${payload.id}`, payload).then(response => {
                resolve(response);
            });
        });
    },
    removeStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`/api/statuses/${payload}`).then(response => {
                resolve(response);
            });
        });
    }
};

const mutations = {
    SET_ORDERS: (state, orders) => (state.orders = orders),
    ADD_ORDER: (state, order) => state.orders.push(order),
    REFRESH_ORDERS: (state, order_id) => {
        const index = state.orders.findIndex(order => order.id == order_id);
        state.orders.splice(index, 1);
    },
    SET_STATUSES: (state, statuses) => {
        state.statuses = statuses;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
